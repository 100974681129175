.Mobile_Electricity {
  width: 100%; }
  .Mobile_Electricity .Mobile_ShowBox {
    padding: 0.4rem 0.63rem 0 0.63rem;
    text-align: center;
    background: #F5F9FF; }
    .Mobile_Electricity .Mobile_ShowBox .Mobile_Title {
      font-size: 0.36rem;
      color: #333; }
      .Mobile_Electricity .Mobile_ShowBox .Mobile_Title span {
        color: #006AEB; }
    .Mobile_Electricity .Mobile_ShowBox .Mobile_SubTitle {
      font-size: 0.2rem;
      color: #666;
      margin: 0.2rem 0 0.4rem 0; }
    .Mobile_Electricity .Mobile_ShowBox .Mobile_SaaS {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
      .Mobile_Electricity .Mobile_ShowBox .Mobile_SaaS div {
        width: 2.9rem;
        height: 2.3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: #fff;
        position: relative;
        margin-bottom: 0.4rem; }
        .Mobile_Electricity .Mobile_ShowBox .Mobile_SaaS div .Mobile_SaaSHead {
          font-size: 0.3rem;
          color: #666;
          padding-top: 0.3rem; }
        .Mobile_Electricity .Mobile_ShowBox .Mobile_SaaS div .Line {
          width: 0.52rem;
          height: 0.04rem;
          background: #006AEB;
          margin: 0 auto;
          position: absolute;
          bottom: 0; }
  .Mobile_Electricity .Mobile_Func {
    width: 100%;
    text-align: center;
    padding: 0.4rem 0.63rem 0 0.63rem;
    background: url("../../assets/common/images/jichugongneng-bg@3x.png") no-repeat;
    background-size: 100% 100%; }
    .Mobile_Electricity .Mobile_Func .Mobile_Title {
      font-size: 0.36rem;
      color: #333; }
      .Mobile_Electricity .Mobile_Func .Mobile_Title span {
        color: #006AEB; }
    .Mobile_Electricity .Mobile_Func .Mobile_SubTitle {
      font-size: 0.2rem;
      color: #666;
      margin: 0.2rem 0 0.4rem 0; }
    .Mobile_Electricity .Mobile_Func .Mobile_ContentBox {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
      .Mobile_Electricity .Mobile_Func .Mobile_ContentBox div {
        width: 2.4rem;
        flex-direction: column;
        margin-bottom: 0.4rem; }
        .Mobile_Electricity .Mobile_Func .Mobile_ContentBox div .Mobile_ContantOrder {
          font-size: 0.36rem;
          color: #fff;
          text-shadow: 0 0.01rem #006AEB, 0.01rem 0 #006AEB, -0.01rem 0 #006AEB, 0 -0.01rem #006AEB;
          background: url(../../assets/common/images/gongneng-tu@3x.png) no-repeat center center;
          background-size: auto 100%; }
        .Mobile_Electricity .Mobile_Func .Mobile_ContentBox div .Mobile_ContentTitle {
          margin: 0.3rem auto 0.2rem auto;
          font-size: 0.3rem;
          color: #333; }
        .Mobile_Electricity .Mobile_Func .Mobile_ContentBox div .Mobile_ContentText {
          font-size: 0.24rem;
          color: #666; }
  .Mobile_Electricity .Mobile_Case {
    width: 100%;
    text-align: center;
    padding: 0.4rem 0.62rem 0.4rem 0.65rem; }
    .Mobile_Electricity .Mobile_Case .Mobile_Title {
      font-size: 0.36rem;
      color: #333;
      margin-bottom: 0.1rem; }
    .Mobile_Electricity .Mobile_Case table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0 0.3rem; }
      .Mobile_Electricity .Mobile_Case table td {
        width: 2.92rem;
        height: 2.92rem; }
        .Mobile_Electricity .Mobile_Case table td img {
          width: 2.92rem;
          height: 2.92rem; }
    .Mobile_Electricity .Mobile_Case button {
      width: 1.8rem;
      height: 0.6rem;
      background: linear-gradient(90deg, #006aeb, #009eff);
      margin: 0 auto;
      border-radius: 0.06rem; }
