body {
  overflow-x: hidden; }

.AppContainer {
  width: 100%;
  height: 100%;
  background: white; }
  .AppContainer .App_Middle_one {
    width: 1200px;
    margin: 0 auto;
    text-align: center;
    padding: 60px 0 80px 0; }
    .AppContainer .App_Middle_one .Title {
      color: #333;
      font-size: 24px;
      margin-bottom: 15px; }
      .AppContainer .App_Middle_one .Title span {
        color: #006AEB; }
    .AppContainer .App_Middle_one .SubTitle {
      color: #666;
      font-size: 16px;
      width: 520px;
      margin: 0 auto; }
    .AppContainer .App_Middle_one .IconList {
      width: 100%;
      margin: 0 auto;
      overflow: hidden;
      margin-top: 60px; }
      .AppContainer .App_Middle_one .IconList li {
        width: 260px;
        float: left;
        margin-right: 210px;
        padding-top: 20px;
        user-select: none; }
        .AppContainer .App_Middle_one .IconList li img {
          margin-bottom: 30px;
          transition: all 1.5s ease-in-out; }
        .AppContainer .App_Middle_one .IconList li .ListTitle {
          color: #333;
          font-size: 20px; }
        .AppContainer .App_Middle_one .IconList li .Line {
          width: 20px;
          height: 2px;
          background: #DEDEDE;
          margin: 10px auto; }
        .AppContainer .App_Middle_one .IconList li .Content {
          width: 206px;
          margin: 10px auto 0 auto;
          text-align: left; }
      .AppContainer .App_Middle_one .IconList li:hover img {
        transform: rotateY(180deg); }
      .AppContainer .App_Middle_one .IconList li:nth-child(3) {
        margin-right: 0; }
  .AppContainer .App_Middle_two {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding: 60px 0 40px 0;
    background: #f5f9ff; }
    .AppContainer .App_Middle_two .Title {
      color: #333;
      font-size: 24px;
      margin-bottom: 15px; }
      .AppContainer .App_Middle_two .Title span {
        color: #006AEB; }
    .AppContainer .App_Middle_two .SubTitle {
      color: #666;
      font-size: 16px;
      width: 520px;
      margin: 0 auto; }
    .AppContainer .App_Middle_two .IconList {
      width: 1200px;
      margin: 48px auto 0 auto;
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap; }
      .AppContainer .App_Middle_two .IconList li {
        width: 360px;
        height: 300px;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex-wrap: wrap; }
        .AppContainer .App_Middle_two .IconList li .ListTitle {
          color: #333333;
          font-size: 20px;
          margin: 20px 0; }
        .AppContainer .App_Middle_two .IconList li .Content {
          color: #666;
          font-size: 14px;
          width: 208px;
          margin: 0 auto;
          text-align: left; }
      .AppContainer .App_Middle_two .IconList li:nth-child(3) {
        margin-right: 0; }
      .AppContainer .App_Middle_two .IconList li:nth-child(6) {
        margin-right: 0; }
      .AppContainer .App_Middle_two .IconList li:hover {
        cursor: pointer;
        background: #fff;
        box-shadow: 0px 3px 8px 0px rgba(0, 106, 235, 0.15); }
  .AppContainer .App_Middle_three {
    width: 1200px;
    margin: 0 auto;
    text-align: center;
    padding-top: 60px;
    margin-bottom: 60px; }
    .AppContainer .App_Middle_three .Title {
      color: #333;
      font-size: 24px;
      margin-bottom: 15px; }
      .AppContainer .App_Middle_three .Title span {
        color: #006AEB; }
    .AppContainer .App_Middle_three .SubTitle {
      color: #666;
      font-size: 16px;
      width: 520px;
      margin: 0 auto; }
    .AppContainer .App_Middle_three .Content {
      width: 100%;
      overflow: hidden;
      margin: 56px auto 0 auto;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap; }
      .AppContainer .App_Middle_three .Content a {
        width: 292px;
        height: 292px;
        position: relative;
        margin: 0 8px 30px 0;
        overflow: hidden; }
        .AppContainer .App_Middle_three .Content a .Img {
          width: 292px;
          height: 292px;
          transition: all 1s;
          transform-origin: left bottom; }
        .AppContainer .App_Middle_three .Content a .Mask {
          position: absolute;
          text-align: center;
          width: 292px;
          height: 292px;
          left: 0;
          top: 0;
          display: none;
          opacity: 0.6; }
        .AppContainer .App_Middle_three .Content a .TextBox {
          width: 230px;
          text-align: center;
          position: absolute;
          color: white;
          z-index: 1;
          font-size: 20px;
          display: none; }
        .AppContainer .App_Middle_three .Content a .Line {
          width: 260px;
          height: 240px;
          position: absolute;
          border: 2px solid #FFF;
          z-index: 1;
          display: none;
          border-radius: 4px; }
          .AppContainer .App_Middle_three .Content a .Line img {
            width: 50px;
            height: 36px;
            position: absolute;
            right: 20px;
            bottom: -18px; }
      .AppContainer .App_Middle_three .Content a:hover {
        cursor: pointer; }
        .AppContainer .App_Middle_three .Content a:hover .Img {
          transform: scale(1.1); }
        .AppContainer .App_Middle_three .Content a:hover .Mask {
          display: block; }
        .AppContainer .App_Middle_three .Content a:hover .TextBox {
          display: block; }
        .AppContainer .App_Middle_three .Content a:hover .Line {
          display: block; }
    .AppContainer .App_Middle_three .Button {
      display: block;
      width: 150px;
      height: 50px;
      background: linear-gradient(90deg, #006aeb, #009eff);
      box-shadow: 0px 10px 20px 0px #b5d4ff;
      border-radius: 6px;
      margin: 0 auto;
      color: #fff;
      margin-top: 53px;
      font-size: 16px;
      text-align: center;
      line-height: 50px; }
    .AppContainer .App_Middle_three .Button:hover {
      cursor: pointer; }
  .AppContainer .App_Middle_four {
    width: 1200px;
    margin: 0 auto;
    text-align: center;
    padding: 60px 0; }
    .AppContainer .App_Middle_four .Title {
      color: #333;
      font-size: 24px;
      margin-bottom: 15px; }
      .AppContainer .App_Middle_four .Title span {
        color: #006AEB; }
    .AppContainer .App_Middle_four .SubTitle {
      color: #666;
      font-size: 16px;
      width: 520px;
      margin: 0 auto; }
    .AppContainer .App_Middle_four .Buttom {
      width: 100%;
      height: 360px;
      margin-top: 60px;
      margin-bottom: 40px; }
      .AppContainer .App_Middle_four .Buttom a {
        width: 360px;
        height: 360px;
        float: left;
        overflow: hidden;
        background: white;
        box-shadow: 0px 5px 15px 0px rgba(49, 49, 49, 0.15);
        margin-right: 30px;
        position: relative; }
        .AppContainer .App_Middle_four .Buttom a .ImgBox {
          width: 360px;
          height: 240px; }
          .AppContainer .App_Middle_four .Buttom a .ImgBox .Img {
            width: 360px;
            height: 240px; }
        .AppContainer .App_Middle_four .Buttom a .P_Con {
          color: #333;
          margin: 12px 20px 0 10px;
          text-align: left; }
        .AppContainer .App_Middle_four .Buttom a .P_Head {
          padding-top: 10px;
          font-size: 16px; }
        .AppContainer .App_Middle_four .Buttom a .P_Text {
          font-size: 14px;
          color: #666;
          padding-bottom: 10px; }
        .AppContainer .App_Middle_four .Buttom a .Mask_DeatilsBtm {
          position: absolute;
          top: 0;
          left: 0;
          width: 360px;
          height: 240px;
          background: #006aeb;
          opacity: 0.8;
          display: none; }
        .AppContainer .App_Middle_four .Buttom a .Triangle {
          width: 0;
          height: 0;
          border-left: 12px solid transparent;
          border-right: 12px solid transparent;
          border-bottom: 18px solid white;
          position: absolute;
          bottom: 110px;
          right: 60px;
          display: none; }
        .AppContainer .App_Middle_four .Buttom a .MaskText {
          width: 360px;
          height: 240px;
          text-align: center;
          position: absolute;
          top: 0;
          left: 0;
          padding: 88px 0;
          display: none; }
          .AppContainer .App_Middle_four .Buttom a .MaskText p:nth-child(1) {
            font-size: 14px;
            color: white; }
          .AppContainer .App_Middle_four .Buttom a .MaskText p:nth-child(2) {
            font-size: 24px;
            color: white; }
      .AppContainer .App_Middle_four .Buttom a:nth-child(3) {
        margin-right: 0; }
      .AppContainer .App_Middle_four .Buttom a:hover {
        cursor: pointer; }
        .AppContainer .App_Middle_four .Buttom a:hover .Triangle {
          display: block; }
        .AppContainer .App_Middle_four .Buttom a:hover .MaskText, .AppContainer .App_Middle_four .Buttom a:hover .Mask_DeatilsBtm {
          display: block; }
        .AppContainer .App_Middle_four .Buttom a:hover .P_Head {
          color: #006AEB; }
    .AppContainer .App_Middle_four button {
      width: 150px;
      height: 50px;
      background: linear-gradient(90deg, #006aeb, #009eff);
      box-shadow: 0px 10px 20px 0px #b5d4ff;
      border-radius: 6px;
      margin: 0 auto;
      margin-top: 53px;
      text-align: center;
      font-size: 16px;
      color: #fff; }
    .AppContainer .App_Middle_four button:hover {
      cursor: pointer; }
  .AppContainer .App_Process {
    width: 100%;
    height: 548px;
    background: url("../../assets/common/images/liucheng-bg.png") no-repeat; }
    .AppContainer .App_Process .Title {
      color: #fff;
      font-size: 24px;
      margin-bottom: 15px;
      text-align: center;
      padding-top: 60px; }
    .AppContainer .App_Process .SubTitle {
      color: #fff;
      font-size: 16px;
      width: 520px;
      margin: 0 auto;
      text-align: center; }
    .AppContainer .App_Process .Content {
      width: 1200px;
      height: 214px;
      margin: 80px auto 0 auto; }
      .AppContainer .App_Process .Content .Cont_A {
        width: 104px;
        height: 214px;
        float: left;
        position: relative;
        margin-right: 78px; }
        .AppContainer .App_Process .Content .Cont_A .Cont_Box {
          width: 104px;
          height: 104px;
          background: #3a3976;
          border-radius: 50%;
          position: absolute;
          top: 0;
          left: 0; }
          .AppContainer .App_Process .Content .Cont_A .Cont_Box .Img_Box {
            width: 84px;
            height: 84px;
            border: 4px solid #fff;
            border-radius: 50%;
            position: absolute;
            top: 10px;
            left: 10px;
            text-align: center;
            padding-top: 17px; }
        .AppContainer .App_Process .Content .Cont_A .Line {
          width: 2px;
          height: 40px;
          border: 1px dashed #fff;
          position: absolute;
          left: 52px;
          bottom: 60px; }
        .AppContainer .App_Process .Content .Cont_A .Dot {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background: #006AEB;
          position: absolute;
          bottom: 40px;
          left: 47px; }
        .AppContainer .App_Process .Content .Cont_A .Head {
          color: #fff;
          text-align: center;
          font-size: 20px;
          position: relative;
          top: 180px; }
      .AppContainer .App_Process .Content .Cont_A:last-child {
        margin-right: 0; }
  .AppContainer .ant-message .anticon svg {
    display: none; }
