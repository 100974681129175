.Mobile_Web {
  width: 100%; }
  .Mobile_Web .Mobile_WebBox {
    padding: 0.28rem 0.64rem 0.4rem 0.64rem;
    text-align: center; }
    .Mobile_Web .Mobile_WebBox .Mobile_Title {
      font-size: 0.36rem;
      color: #333; }
      .Mobile_Web .Mobile_WebBox .Mobile_Title span {
        color: #006AEB; }
    .Mobile_Web .Mobile_WebBox .Mobile_SubTitle {
      font-size: 0.2rem;
      color: #666;
      margin: 0.2rem auto 0.4rem auto;
      width: 4.2rem; }
    .Mobile_Web .Mobile_WebBox .Mobile_Show {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
      .Mobile_Web .Mobile_WebBox .Mobile_Show div {
        width: 2.8rem;
        height: 2.8rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: #006AEB;
        color: #fff;
        margin-bottom: 0.4rem;
        background: linear-gradient(90deg, #006aeb, #009eff);
        box-shadow: 0rem 0.05rem 0.15rem 0rem rgba(0, 255, 113, 0.35);
        border-radius: 0.06rem;
        padding: 0 0.05rem; }
        .Mobile_Web .Mobile_WebBox .Mobile_Show div .Mobile_ShowTitle {
          font-size: 0.3rem;
          padding: 0.2rem 0 0.2rem 0; }
        .Mobile_Web .Mobile_WebBox .Mobile_Show div .Mobile_ShowText {
          font-size: 0.24rem; }
  .Mobile_Web .Mobile_WebPro {
    padding: 0.4rem 0.64rem 0 0.64rem;
    text-align: center; }
    .Mobile_Web .Mobile_WebPro .Mobile_ProTitle {
      font-size: 0.36rem;
      color: #333; }
      .Mobile_Web .Mobile_WebPro .Mobile_ProTitle span {
        color: #006AEB; }
    .Mobile_Web .Mobile_WebPro .Mobile_ProSubTitle {
      font-size: 0.2rem;
      color: #666;
      margin: 0.2rem 0 0.4rem 0; }
    .Mobile_Web .Mobile_WebPro .Mobile_ProTextBox {
      width: 100%;
      text-align: left;
      display: flex;
      flex-direction: column;
      margin-bottom: 0.7rem; }
      .Mobile_Web .Mobile_WebPro .Mobile_ProTextBox div {
        display: flex;
        flex-wrap: wrap;
        justify-content: left;
        align-items: center; }
        .Mobile_Web .Mobile_WebPro .Mobile_ProTextBox div .Mobile_ProTextHead {
          font-size: 0.3rem;
          color: #333;
          padding-left: 0.2rem; }
      .Mobile_Web .Mobile_WebPro .Mobile_ProTextBox .Mobile_ProTextContent {
        font-size: 0.24rem;
        color: #666; }
