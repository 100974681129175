body {
  overflow-x: hidden; }

.SaaS_Middle_one {
  width: 100%;
  height: 660px;
  background: #f5f9ff; }
  .SaaS_Middle_one .ContainerBox {
    width: 1200px;
    height: 100%;
    overflow: hidden;
    margin: 0 auto;
    padding-top: 60px; }
  .SaaS_Middle_one .Title {
    color: #333;
    font-size: 24px;
    margin-bottom: 15px;
    text-align: center; }
    .SaaS_Middle_one .Title span {
      color: #006AEB; }
  .SaaS_Middle_one .SubTitle {
    color: #666;
    font-size: 16px;
    width: 520px;
    margin: 0 auto;
    text-align: center; }
  .SaaS_Middle_one .Content {
    width: 1200px;
    height: 290px;
    margin: 60px auto 0 auto;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .SaaS_Middle_one .Content li {
      width: 20%;
      height: 290px;
      text-align: center;
      position: relative; }
      .SaaS_Middle_one .Content li .True {
        width: 100%;
        height: 230px;
        padding-top: 66px;
        position: absolute;
        bottom: 0;
        left: 0;
        background: #fff; }
        .SaaS_Middle_one .Content li .True .True_Title {
          font-size: 20px;
          color: #666666;
          margin-top: 32px; }
      .SaaS_Middle_one .Content li .False {
        width: 100%;
        height: 290px;
        background: linear-gradient(90deg, #006aeb, #009eff);
        box-shadow: 0px 3px 8px 0px rgba(0, 106, 235, 0.15);
        padding-top: 32px;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        transition: all 0.8s ease-out; }
        .SaaS_Middle_one .Content li .False .False_Title {
          margin: 30px 0 10px 0;
          color: #FFFFFFFF;
          font-size: 20px; }
        .SaaS_Middle_one .Content li .False table {
          font-size: 14px;
          color: #fff;
          margin: 0 auto;
          width: 100%;
          height: 140px;
          text-align: left;
          margin-left: 10px; }
        .SaaS_Middle_one .Content li .False .P_Cont {
          font-size: 14px;
          color: #fff;
          width: 100%;
          margin: 0 auto;
          text-align: center;
          line-height: 32px; }
        .SaaS_Middle_one .Content li .False .P_Text {
          font-size: 14px;
          color: #fff;
          width: 80%;
          margin: 0 auto;
          text-align: left; }
      .SaaS_Middle_one .Content li .BottomLine {
        display: block;
        width: 52px;
        height: 4px;
        background: #006AEB;
        position: absolute;
        bottom: 0;
        left: 50%;
        margin-left: -26px; }
    .SaaS_Middle_one .Content li:last-child {
      margin-right: 0; }
    .SaaS_Middle_one .Content li:hover {
      cursor: pointer; }
      .SaaS_Middle_one .Content li:hover .True {
        display: none; }
      .SaaS_Middle_one .Content li:hover .False {
        opacity: 1;
        filter: alpha(opacity=1); }
      .SaaS_Middle_one .Content li:hover .BottomLine {
        background: #fff; }

.SaaS_Middle_two {
  width: 100%;
  height: 750px;
  background: url("../../assets/common/images/jichugongneng-bg.png") no-repeat; }
  .SaaS_Middle_two .ContainerBox {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    padding-top: 60px; }
    .SaaS_Middle_two .ContainerBox .Title {
      color: #333;
      font-size: 24px;
      margin-bottom: 15px;
      text-align: center; }
      .SaaS_Middle_two .ContainerBox .Title span {
        color: #006AEB; }
    .SaaS_Middle_two .ContainerBox .SubTitle {
      color: #666;
      font-size: 16px;
      width: 520px;
      margin: 0 auto;
      text-align: center; }
    .SaaS_Middle_two .ContainerBox .Content {
      width: 1200px;
      overflow: hidden;
      margin-top: 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap; }
      .SaaS_Middle_two .ContainerBox .Content li {
        width: 285px;
        height: 230px;
        position: relative;
        margin-bottom: 30px; }
        .SaaS_Middle_two .ContainerBox .Content li .Head {
          position: absolute;
          top: 0;
          font-size: 36px;
          color: #fff;
          text-shadow: 0 1px #006AEB, 1px 0 #006AEB, -1px 0 #006AEB, 0 -1px #006AEB; }
        .SaaS_Middle_two .ContainerBox .Content li .TextTitle {
          font-size: 20px;
          color: #333333;
          text-align: center;
          margin: 30px 0 15px 0; }
        .SaaS_Middle_two .ContainerBox .Content li .Text {
          width: 172px;
          margin: 0 auto;
          text-align: left; }
      .SaaS_Middle_two .ContainerBox .Content a:nth-child(4) {
        margin-right: 0; }
      .SaaS_Middle_two .ContainerBox .Content a:nth-child(8) {
        margin-right: 0; }

.SaaS_Middle_three {
  width: 1200px;
  margin: 0 auto;
  text-align: center;
  padding: 60px 0; }
  .SaaS_Middle_three .Title {
    color: #333;
    font-size: 24px;
    margin-bottom: 15px; }
    .SaaS_Middle_three .Title span {
      color: #006AEB; }
  .SaaS_Middle_three .SubTitle {
    color: #666;
    font-size: 16px;
    width: 520px;
    margin: 0 auto; }
  .SaaS_Middle_three .Content {
    width: 100%;
    overflow: hidden;
    margin: 56px auto 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; }
    .SaaS_Middle_three .Content a {
      width: 292px;
      height: 292px;
      position: relative;
      margin: 0 8px 30px 0;
      overflow: hidden; }
      .SaaS_Middle_three .Content a .Img {
        width: 292px;
        height: 292px;
        transition: all 1s;
        transform-origin: left bottom; }
      .SaaS_Middle_three .Content a .Mask {
        position: absolute;
        text-align: center;
        width: 292px;
        height: 292px;
        left: 0;
        top: 0;
        display: none;
        opacity: 0.6; }
      .SaaS_Middle_three .Content a .Line {
        width: 260px;
        height: 240px;
        position: absolute;
        border: 2px solid #FFF;
        z-index: 1;
        display: none;
        border-radius: 4px; }
        .SaaS_Middle_three .Content a .Line img {
          width: 50px;
          height: 36px;
          position: absolute;
          right: 20px;
          bottom: -18px; }
      .SaaS_Middle_three .Content a .TextBox {
        width: 230px;
        text-align: center;
        position: absolute;
        color: white;
        z-index: 1;
        font-size: 20px;
        display: none; }
    .SaaS_Middle_three .Content a:nth-child(4) {
      margin-right: 0; }
    .SaaS_Middle_three .Content a:nth-child(8) {
      margin-right: 0; }
    .SaaS_Middle_three .Content a:hover {
      cursor: pointer; }
      .SaaS_Middle_three .Content a:hover .Img {
        transform: scale(1.1); }
      .SaaS_Middle_three .Content a:hover .Mask {
        display: block; }
      .SaaS_Middle_three .Content a:hover .TextBox {
        display: block; }
      .SaaS_Middle_three .Content a:hover .Line {
        display: block; }
  .SaaS_Middle_three .Button {
    display: block;
    width: 150px;
    height: 50px;
    background: linear-gradient(90deg, #006aeb, #009eff);
    box-shadow: 0px 10px 20px 0px #b5d4ff;
    border-radius: 6px;
    color: #fff;
    font-size: 16px;
    margin: 0 auto;
    margin-top: 53px;
    line-height: 50px;
    text-align: center; }
  .SaaS_Middle_three .Button:hover {
    cursor: pointer; }

.SaaS_Middle_four {
  width: 1200px;
  margin: 0 auto;
  text-align: center;
  padding: 60px 0 40px 0; }
  .SaaS_Middle_four .Title {
    color: #333;
    font-size: 24px;
    margin-bottom: 15px; }
    .SaaS_Middle_four .Title span {
      color: #006AEB; }
  .SaaS_Middle_four .SubTitle {
    color: #666;
    font-size: 16px;
    width: 520px;
    margin: 0 auto; }
  .SaaS_Middle_four .Buttom {
    width: 100%;
    height: 360px;
    margin-top: 60px;
    margin-bottom: 60px; }
    .SaaS_Middle_four .Buttom a {
      width: 360px;
      height: 360px;
      float: left;
      overflow: hidden;
      background: white;
      box-shadow: 0px 3px 5px 0px rgba(49, 49, 49, 0.15);
      margin-right: 30px;
      position: relative; }
      .SaaS_Middle_four .Buttom a .ImgBox {
        width: 360px;
        height: 240px; }
        .SaaS_Middle_four .Buttom a .ImgBox .Img {
          width: 360px;
          height: 240px; }
      .SaaS_Middle_four .Buttom a .P_Con {
        margin: 12px 20px 0 10px;
        text-align: left; }
      .SaaS_Middle_four .Buttom a .P_Head {
        color: #333;
        padding-top: 10px;
        font-size: 16px; }
      .SaaS_Middle_four .Buttom a .P_Text {
        font-size: 14px;
        color: #666;
        padding-bottom: 10px; }
      .SaaS_Middle_four .Buttom a .Mask_DeatilsBtm {
        position: absolute;
        top: 0;
        left: 0;
        width: 360px;
        height: 240px;
        background: #006aeb;
        opacity: 0.8;
        display: none; }
      .SaaS_Middle_four .Buttom a .Triangle {
        width: 0;
        height: 0;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-bottom: 18px solid white;
        position: absolute;
        bottom: 110px;
        right: 60px;
        display: none; }
      .SaaS_Middle_four .Buttom a .MaskText {
        width: 360px;
        height: 240px;
        text-align: center;
        position: absolute;
        top: 0;
        left: 0;
        padding: 88px 0;
        display: none; }
        .SaaS_Middle_four .Buttom a .MaskText p:nth-child(1) {
          font-size: 14px;
          color: white; }
        .SaaS_Middle_four .Buttom a .MaskText p:nth-child(2) {
          font-size: 24px;
          color: white; }
    .SaaS_Middle_four .Buttom a:nth-child(3) {
      margin-right: 0; }
    .SaaS_Middle_four .Buttom a:hover {
      cursor: pointer; }
      .SaaS_Middle_four .Buttom a:hover .Triangle {
        display: block; }
      .SaaS_Middle_four .Buttom a:hover .MaskText, .SaaS_Middle_four .Buttom a:hover .Mask_DeatilsBtm {
        display: block; }
      .SaaS_Middle_four .Buttom a:hover .P_Head {
        color: #006AEB; }
  .SaaS_Middle_four button {
    width: 150px;
    height: 50px;
    line-height: 50px;
    background: linear-gradient(90deg, #006aeb, #009eff);
    box-shadow: 0px 10px 20px 0px #b5d4ff;
    color: #fff;
    font-size: 16px;
    border-radius: 6px;
    margin: 0 auto;
    margin-top: 53px;
    text-align: center; }
  .SaaS_Middle_four button:hover {
    cursor: pointer; }

.SaaS_Process {
  width: 100%;
  height: 820px;
  background: url("../../assets/common/images/gogneng-bg.png") no-repeat; }
  .SaaS_Process .ContainerBox {
    width: 1300px;
    height: 100%;
    margin: 0 auto;
    padding: 0 0 60px 0; }
    .SaaS_Process .ContainerBox .Title {
      font-size: 24px;
      text-align: center;
      padding-top: 60px;
      margin-bottom: 30px;
      color: #fefefe; }
    .SaaS_Process .ContainerBox .TabBox {
      margin-top: 30px; }
      .SaaS_Process .ContainerBox .TabBox .ant-tabs-tab {
        color: #fff;
        opacity: 0.3;
        padding-right: 60px;
        font-size: 18px; }
      .SaaS_Process .ContainerBox .TabBox .ant-tabs-tab:last-child {
        margin-bottom: 35px !important; }
      .SaaS_Process .ContainerBox .TabBox .ant-tabs-tab:hover {
        color: #fff;
        opacity: 1; }
      .SaaS_Process .ContainerBox .TabBox .ant-tabs-ink-bar {
        height: 80px !important; }
      .SaaS_Process .ContainerBox .TabBox .ant-tabs-tab-active {
        color: #006AEB !important;
        opacity: 1; }
      .SaaS_Process .ContainerBox .TabBox .ant-tabs-content {
        padding-left: 40px; }
      .SaaS_Process .ContainerBox .TabBox .Process_Img {
        margin-top: 20px; }
      .SaaS_Process .ContainerBox .TabBox .Process_Text {
        color: white;
        text-indent: 2em;
        margin-top: 80px; }
