.DetailsContainer {
  width: 100%;
  height: 100%;
  background: white;
  overflow: hidden; }
  .DetailsContainer .DetailsBox {
    width: 1200px;
    height: 100%;
    margin: 0 auto; }
    .DetailsContainer .DetailsBox .DetailsTitle {
      height: 40px; }
      .DetailsContainer .DetailsBox .DetailsTitle img {
        margin: 10px 10px 0 0; }
      .DetailsContainer .DetailsBox .DetailsTitle p {
        font-size: 14px;
        color: #333333;
        line-height: 40px; }
        .DetailsContainer .DetailsBox .DetailsTitle p span {
          color: #006AEB; }
    .DetailsContainer .DetailsBox .DetailsLift {
      width: 800px;
      height: 100%;
      float: left;
      text-align: left;
      margin-right: 110px; }
      .DetailsContainer .DetailsBox .DetailsLift .P_Con {
        font-size: 14px;
        color: #333333; }
      .DetailsContainer .DetailsBox .DetailsLift .P_one {
        margin: 30px 0 20px 0;
        font-size: 16px; }
      .DetailsContainer .DetailsBox .DetailsLift .P_three {
        width: 800px;
        height: 2px;
        background: #DDDDDD;
        margin: 20px 0 30px 0; }
      .DetailsContainer .DetailsBox .DetailsLift img {
        width: 800px;
        height: 400px;
        margin: 30px 0; }
    .DetailsContainer .DetailsBox .DetailsRight {
      width: 290px;
      height: 100%;
      color: #333333;
      text-align: left; }
      .DetailsContainer .DetailsBox .DetailsRight .RightHead {
        font-size: 20px;
        margin-bottom: 20px; }
      .DetailsContainer .DetailsBox .DetailsRight a {
        width: 292px;
        height: 292px;
        position: relative;
        margin-bottom: 20px;
        overflow: hidden; }
        .DetailsContainer .DetailsBox .DetailsRight a .Img {
          width: 292px;
          height: 292px;
          transition: all 1s;
          transform-origin: left bottom; }
        .DetailsContainer .DetailsBox .DetailsRight a .Mask {
          position: absolute;
          text-align: center;
          width: 292px;
          height: 292px;
          left: 0;
          top: 0;
          display: none;
          opacity: 0.6; }
        .DetailsContainer .DetailsBox .DetailsRight a .TextBox {
          width: 230px;
          text-align: center;
          position: absolute;
          color: white;
          z-index: 1;
          font-size: 20px;
          display: none; }
        .DetailsContainer .DetailsBox .DetailsRight a .Line {
          width: 260px;
          height: 240px;
          position: absolute;
          border: 2px solid #FFF;
          z-index: 1;
          display: none;
          border-radius: 4px; }
          .DetailsContainer .DetailsBox .DetailsRight a .Line img {
            width: 50px;
            height: 36px;
            position: absolute;
            right: 20px;
            bottom: -18px; }
      .DetailsContainer .DetailsBox .DetailsRight a:hover {
        cursor: pointer; }
        .DetailsContainer .DetailsBox .DetailsRight a:hover .Img {
          transform: scale(1.1); }
        .DetailsContainer .DetailsBox .DetailsRight a:hover .Mask {
          display: block; }
        .DetailsContainer .DetailsBox .DetailsRight a:hover .TextBox {
          display: block; }
        .DetailsContainer .DetailsBox .DetailsRight a:hover .Line {
          display: block; }
    .DetailsContainer .DetailsBox .ButtomTitle {
      font-size: 20px;
      color: #333333;
      padding: 80px 0 30px 0;
      text-align: center; }
    .DetailsContainer .DetailsBox .DeatilsButtom {
      width: 100%;
      height: 100%;
      overflow: hidden;
      margin-bottom: 60px; }
      .DetailsContainer .DetailsBox .DeatilsButtom a {
        width: 360px;
        height: 360px;
        float: left;
        overflow: hidden;
        background: white;
        box-shadow: 0px 3px 5px 0px rgba(49, 49, 49, 0.15);
        border: 1px solid #26313131;
        margin-right: 30px;
        position: relative; }
        .DetailsContainer .DetailsBox .DeatilsButtom a .ImgBox {
          width: 360px;
          height: 240px; }
          .DetailsContainer .DetailsBox .DeatilsButtom a .ImgBox .Img {
            width: 360px;
            height: 240px; }
        .DetailsContainer .DetailsBox .DeatilsButtom a .P_Con {
          margin: 12px 20px 0 10px;
          text-align: left; }
        .DetailsContainer .DetailsBox .DeatilsButtom a .P_Head {
          color: #666;
          padding-top: 10px;
          font-size: 16px; }
        .DetailsContainer .DetailsBox .DeatilsButtom a .P_Text {
          font-size: 14px;
          color: #999999;
          padding-bottom: 10px; }
        .DetailsContainer .DetailsBox .DeatilsButtom a .Mask_DeatilsBtm {
          position: absolute;
          top: 0;
          left: 0;
          width: 360px;
          height: 240px;
          background: #006aeb;
          opacity: 0.8;
          display: none; }
        .DetailsContainer .DetailsBox .DeatilsButtom a .Triangle {
          width: 0;
          height: 0;
          border-left: 12px solid transparent;
          border-right: 12px solid transparent;
          border-bottom: 18px solid white;
          position: absolute;
          bottom: 110px;
          right: 60px;
          display: none; }
        .DetailsContainer .DetailsBox .DeatilsButtom a .MaskText {
          width: 360px;
          height: 240px;
          text-align: center;
          position: absolute;
          top: 0;
          left: 0;
          padding: 88px 0;
          display: none; }
          .DetailsContainer .DetailsBox .DeatilsButtom a .MaskText p:nth-child(1) {
            font-size: 14px;
            color: white; }
          .DetailsContainer .DetailsBox .DeatilsButtom a .MaskText p:nth-child(2) {
            font-size: 24px;
            color: white; }
      .DetailsContainer .DetailsBox .DeatilsButtom a:nth-child(3) {
        margin-right: 0; }
      .DetailsContainer .DetailsBox .DeatilsButtom a:hover {
        cursor: pointer; }
        .DetailsContainer .DetailsBox .DeatilsButtom a:hover .Triangle {
          display: block; }
        .DetailsContainer .DetailsBox .DeatilsButtom a:hover .MaskText, .DetailsContainer .DetailsBox .DeatilsButtom a:hover .Mask_DeatilsBtm {
          display: block; }
        .DetailsContainer .DetailsBox .DeatilsButtom a:hover .P_Head {
          color: #006AEB; }
