body {
  overflow-x: hidden; }

.Web_Middle_one {
  width: 100%;
  background: #f5f9ff;
  padding: 60px 0 120px 0; }
  .Web_Middle_one .ContainerBox {
    width: 1200px;
    height: 100%;
    margin: auto; }
    .Web_Middle_one .ContainerBox .Title {
      color: #333;
      font-size: 24px;
      margin-bottom: 15px;
      text-align: center; }
      .Web_Middle_one .ContainerBox .Title span {
        color: #006AEB; }
    .Web_Middle_one .ContainerBox .SubTitle {
      text-align: center;
      color: #666;
      font-size: 16px;
      width: 520px;
      margin: 0 auto; }
    .Web_Middle_one .ContainerBox .Content {
      width: 1200px;
      height: 280px;
      margin: 80px auto 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .Web_Middle_one .ContainerBox .Content li {
        width: 280px;
        height: 280px;
        position: relative;
        flex: 1; }
        .Web_Middle_one .ContainerBox .Content li .True {
          width: 280px;
          height: 280px;
          background: white;
          border: 1px solid #dddddd;
          position: absolute;
          top: 0;
          left: 0;
          text-align: center;
          padding-top: 70px; }
          .Web_Middle_one .ContainerBox .Content li .True .TextTitle {
            font-size: 20px;
            color: #333;
            margin: 30px 0 14px 0; }
        .Web_Middle_one .ContainerBox .Content li .False {
          display: none;
          width: 280px;
          height: 280px;
          position: absolute;
          top: 0;
          left: 0;
          text-align: center;
          background: #006aeb;
          box-shadow: 0px 7px 15px 0px #bcd8ff; }
          .Web_Middle_one .ContainerBox .Content li .False .TextHead {
            font-size: 20px;
            color: #fff;
            margin: 50px 0 30px 0; }
          .Web_Middle_one .ContainerBox .Content li .False .TextCont {
            width: 204px;
            margin: 0 auto;
            text-align: left;
            color: #fff; }
      .Web_Middle_one .ContainerBox .Content li:last-child {
        margin-right: 0; }
      .Web_Middle_one .ContainerBox .Content li:hover {
        cursor: pointer; }
        .Web_Middle_one .ContainerBox .Content li:hover .True {
          display: none; }
        .Web_Middle_one .ContainerBox .Content li:hover .False {
          display: block; }

.Web_Middle_two {
  width: 100%;
  background: white;
  padding-bottom: 100px; }
  .Web_Middle_two .ContainerBox {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    position: relative; }
    .Web_Middle_two .ContainerBox .Title {
      color: #333;
      font-size: 24px;
      margin-bottom: 15px;
      padding-top: 60px;
      text-align: center; }
      .Web_Middle_two .ContainerBox .Title span {
        color: #006AEB; }
    .Web_Middle_two .ContainerBox .SubTitle {
      text-align: center;
      color: #666;
      font-size: 16px;
      width: 520px;
      margin: 0 auto; }
    .Web_Middle_two .ContainerBox .Content {
      width: 795px;
      margin-top: 70px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap; }
      .Web_Middle_two .ContainerBox .Content li {
        width: 326px;
        height: 120px; }
        .Web_Middle_two .ContainerBox .Content li .TextTitle {
          font-size: 20px;
          color: #333;
          text-align: left;
          margin-bottom: 20px; }
        .Web_Middle_two .ContainerBox .Content li .Text {
          font-size: 14px;
          color: #666; }
      .Web_Middle_two .ContainerBox .Content li:nth-child(2) {
        margin-right: 0; }
      .Web_Middle_two .ContainerBox .Content li:nth-child(4) {
        margin-right: 0; }
      .Web_Middle_two .ContainerBox .Content li:nth-child(6) {
        margin-right: 0; }
    .Web_Middle_two .ContainerBox img {
      width: 414px;
      height: 452px;
      position: absolute;
      right: -120px;
      bottom: 0px; }

.Web_Middle_three {
  width: 1200px;
  margin: 0 auto;
  text-align: center;
  padding: 60px 0;
  clear: both; }
  .Web_Middle_three .Title {
    color: #333;
    font-size: 24px;
    margin-bottom: 15px; }
    .Web_Middle_three .Title span {
      color: #006AEB; }
  .Web_Middle_three .SubTitle {
    color: #666;
    font-size: 16px;
    width: 520px;
    margin: 0 auto; }
  .Web_Middle_three .Content {
    width: 100%;
    overflow: hidden;
    margin: 56px auto 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; }
    .Web_Middle_three .Content a {
      width: 292px;
      height: 292px;
      position: relative;
      margin: 0 8px 30px 0;
      overflow: hidden; }
      .Web_Middle_three .Content a .Img {
        width: 292px;
        height: 292px;
        transition: all 1s;
        transform-origin: left bottom; }
      .Web_Middle_three .Content a .Line {
        width: 260px;
        height: 240px;
        position: absolute;
        border: 2px solid #FFF;
        z-index: 1;
        display: none;
        border-radius: 4px; }
        .Web_Middle_three .Content a .Line img {
          width: 50px;
          height: 36px;
          position: absolute;
          right: 20px;
          bottom: -18px; }
      .Web_Middle_three .Content a .Mask {
        position: absolute;
        text-align: center;
        width: 292px;
        height: 292px;
        left: 0;
        top: 0;
        display: none;
        opacity: 0.6; }
      .Web_Middle_three .Content a .TextBox {
        width: 230px;
        text-align: center;
        position: absolute;
        color: white;
        z-index: 1;
        font-size: 20px;
        display: none; }
    .Web_Middle_three .Content a:nth-child(4) {
      margin-right: 0; }
    .Web_Middle_three .Content a:nth-child(8) {
      margin-right: 0; }
    .Web_Middle_three .Content a:hover {
      cursor: pointer; }
      .Web_Middle_three .Content a:hover .Img {
        transform: scale(1.1); }
      .Web_Middle_three .Content a:hover .Mask {
        display: block; }
      .Web_Middle_three .Content a:hover .TextBox {
        display: block; }
      .Web_Middle_three .Content a:hover .Line {
        display: block; }
  .Web_Middle_three .Button {
    display: block;
    width: 150px;
    height: 50px;
    background: linear-gradient(90deg, #006aeb, #009eff);
    box-shadow: 0px 10px 20px 0px #b5d4ff;
    font-size: 16px;
    color: #fff;
    line-height: 50px;
    border-radius: 6px;
    margin: 0 auto;
    margin-top: 53px;
    text-align: center; }
  .Web_Middle_three .Button:hover {
    cursor: pointer; }

.Web_Middle_four {
  width: 1200px;
  margin: 0 auto;
  text-align: center;
  padding: 60px 0; }
  .Web_Middle_four .Title {
    color: #333;
    font-size: 24px;
    margin-bottom: 15px; }
    .Web_Middle_four .Title span {
      color: #006AEB; }
  .Web_Middle_four .SubTitle {
    color: #666;
    font-size: 16px;
    width: 520px;
    margin: 0 auto; }
  .Web_Middle_four .Buttom {
    width: 100%;
    height: 360px;
    margin-top: 60px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
    .Web_Middle_four .Buttom a {
      display: block;
      width: 360px;
      height: 360px;
      float: left;
      overflow: hidden;
      background: white;
      box-shadow: 0px 3px 5px 0px rgba(49, 49, 49, 0.15);
      margin-right: 30px;
      position: relative; }
      .Web_Middle_four .Buttom a .ImgBox {
        width: 360px;
        height: 240px; }
        .Web_Middle_four .Buttom a .ImgBox .Img {
          width: 360px;
          height: 240px; }
      .Web_Middle_four .Buttom a .P_Con {
        margin: 12px 20px 0 10px;
        text-align: left; }
      .Web_Middle_four .Buttom a .P_Head {
        color: #333;
        padding-top: 10px;
        font-size: 16px; }
      .Web_Middle_four .Buttom a .P_Text {
        font-size: 14px;
        color: #666;
        padding-bottom: 10px; }
      .Web_Middle_four .Buttom a .Mask_DeatilsBtm {
        position: absolute;
        top: 0;
        left: 0;
        width: 360px;
        height: 240px;
        background: #006aeb;
        opacity: 0.8;
        display: none; }
      .Web_Middle_four .Buttom a .Triangle {
        width: 0;
        height: 0;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-bottom: 18px solid white;
        position: absolute;
        bottom: 110px;
        right: 60px;
        display: none; }
      .Web_Middle_four .Buttom a .MaskText {
        width: 360px;
        height: 240px;
        text-align: center;
        position: absolute;
        top: 0;
        left: 0;
        padding: 88px 0;
        display: none; }
        .Web_Middle_four .Buttom a .MaskText p:nth-child(1) {
          font-size: 14px;
          color: white; }
        .Web_Middle_four .Buttom a .MaskText p:nth-child(2) {
          font-size: 24px;
          color: white; }
    .Web_Middle_four .Buttom a:nth-child(3) {
      margin-right: 0; }
    .Web_Middle_four .Buttom a:hover {
      cursor: pointer; }
      .Web_Middle_four .Buttom a:hover .Triangle {
        display: block; }
      .Web_Middle_four .Buttom a:hover .MaskText, .Web_Middle_four .Buttom a:hover .Mask_DeatilsBtm {
        display: block; }
      .Web_Middle_four .Buttom a:hover .P_Head {
        color: #006AEB; }
  .Web_Middle_four button {
    width: 150px;
    height: 50px;
    background: linear-gradient(90deg, #006aeb, #009eff);
    box-shadow: 0px 10px 20px 0px #b5d4ff;
    color: #fff;
    font-size: 16px;
    border-radius: 6px;
    margin: 0 auto;
    margin-top: 53px;
    text-align: center; }
  .Web_Middle_four button:hover {
    cursor: pointer; }

.Web_Process {
  width: 100%;
  height: 546px;
  background: url("../../assets/common/images/wangzhankaifaliucheng-bg.png") no-repeat;
  background-size: 100% 100%; }
  .Web_Process .ContainerBox {
    width: 1200px;
    height: 100%;
    margin: 0 auto; }
    .Web_Process .ContainerBox .Title {
      color: #fff;
      font-size: 24px;
      margin-bottom: 15px;
      text-align: center;
      padding-top: 60px; }
    .Web_Process .ContainerBox .SubTitle {
      text-align: center;
      color: #fff;
      font-size: 16px;
      width: 520px;
      margin: 0 auto; }
    .Web_Process .ContainerBox .Content {
      width: 1200px;
      margin: 110px auto 0 auto;
      overflow: hidden; }
      .Web_Process .ContainerBox .Content li {
        width: 100px;
        text-align: center;
        overflow: hidden;
        float: left;
        margin-right: 175px; }
        .Web_Process .ContainerBox .Content li .Head {
          font-size: 20px;
          color: #fefefe;
          margin-top: 30px; }
      .Web_Process .ContainerBox .Content li:last-child {
        margin-right: 0; }
