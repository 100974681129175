.Mobile_AppContainer {
  width: 100%;
  overflow: hidden; }
  .Mobile_AppContainer .Mobile_AppShowBox {
    padding: 0.56rem 0.66rem 0 0.66rem;
    text-align: center; }
    .Mobile_AppContainer .Mobile_AppShowBox .Mobile_Title {
      font-size: 0.36rem;
      color: #333; }
      .Mobile_AppContainer .Mobile_AppShowBox .Mobile_Title span {
        color: #006AEB; }
    .Mobile_AppContainer .Mobile_AppShowBox .Mobile_SubTitle {
      font-size: 0.2rem;
      color: #666;
      margin: 0.2rem 0 0.4rem 0; }
    .Mobile_AppContainer .Mobile_AppShowBox .Mobile_ImgHead {
      font-size: 0.3rem;
      color: #333;
      margin: 0.28rem 0 0.2rem 0; }
    .Mobile_AppContainer .Mobile_AppShowBox .Line {
      width: 0.4rem;
      height: 0.04rem;
      background: #dedede;
      margin: 0 auto; }
    .Mobile_AppContainer .Mobile_AppShowBox .TextShow {
      width: 4.2rem;
      font-size: 0.24rem;
      color: #666;
      text-align: center;
      margin: 0.2rem auto 0.34rem auto; }
  .Mobile_AppContainer .Mobile_AppPro {
    width: 100%;
    height: 8.83rem;
    background: url("../../assets/common/images/APPkaifaliucheng@3x.png") no-repeat;
    background-size: 100% 100%;
    text-align: center;
    color: #fff;
    padding: 0.39rem 0.66rem 0 0.66rem; }
    .Mobile_AppContainer .Mobile_AppPro .Mobile_Title {
      font-size: 0.36rem;
      margin-bottom: 0.17rem; }
    .Mobile_AppContainer .Mobile_AppPro .Mobile_SubTitle {
      font-size: 0.2rem; }
  .Mobile_AppContainer .Mobile_Case {
    width: 100%;
    text-align: center;
    padding: 0.4rem 0.62rem 0.4rem 0.65rem; }
    .Mobile_AppContainer .Mobile_Case .Mobile_Title {
      font-size: 0.36rem;
      color: #333;
      margin-bottom: 0.1rem; }
    .Mobile_AppContainer .Mobile_Case table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0 0.3rem; }
      .Mobile_AppContainer .Mobile_Case table td {
        width: 2.92rem;
        height: 2.92rem; }
        .Mobile_AppContainer .Mobile_Case table td img {
          width: 2.92rem;
          height: 2.92rem; }
    .Mobile_AppContainer .Mobile_Case button {
      width: 1.8rem;
      height: 0.6rem;
      background: linear-gradient(90deg, #006aeb, #009eff);
      margin: 0 auto;
      border-radius: 0.06rem; }
